import React from 'react';
import { Link } from 'react-router-dom';
import { FileTextIcon, URLGlobe } from 'common/Icons';
import { Tooltip, CircularProgress } from '@material-ui/core';
import { getColor } from '../../ArchivesSidebar.utils';
import { propTypes } from './ChildItem.props';
import makeStyles from './ChildItem.styles';

export default function ChildItem ({ child, handleArchiveClick, parent, prevSearchQuery }) {
  const { name, id, riskScore } = child;
  const isFile = id.includes('file');
  const fileDetailsLink = `/ui/details/file/${id}`;
  const urlDetailsLink = `/ui/details/url/${id}`;
  const { status } = parent;
  const useStyles = makeStyles();
  const classes = useStyles({ riskScore });

  const color = getColor(riskScore);
  const backgroundColor = classes.tooltipBackground;

  return (
    status === 'RUNNING'
      ? (
        <div className={classes.itemWrapper}>
          <Tooltip title={name} classes={{ tooltip: classes.tooltip }}>
            <div className={classes.fileNameLoading}><CircularProgress className={classes.loading} size={12} />{name}</div>
          </Tooltip>
        </div>
        )
      : (
      <Link className={classes.link} to={isFile ? fileDetailsLink : urlDetailsLink} state={{ prevSearchQuery }} onClick={handleArchiveClick}>
        <div className={classes.itemWrapper}>
          <Tooltip title={name} classes={{ tooltip: classes.tooltip + ' ' + backgroundColor }}>
              {isFile
                ? <div className={classes.fileName}><FileTextIcon className={classes.fileIcon} color={color} />{name}</div>
                : <div className={classes.fileName}><URLGlobe className={classes.fileIcon} color={color} />{name}</div>
              }

          </Tooltip>
        </div>
      </Link>
        )
  );
}
ChildItem.propTypes = propTypes;
