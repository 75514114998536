import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ShieldFill } from 'common/Icons';
import { propTypes } from './ResultBlock.props';
import makeStyles from './ResultBlock.styles';

const useStyles = makeStyles();

export default function ResultBlock ({ type, total, since }) {
  const classes = useStyles();
  const [t] = useTranslation();

  return <div className={`${classes.block} ${classes[type]}`}>
    <div>
      <ShieldFill className={`${classes.icon} ${classes[type + 'icon']}`} />
    </div>
    <div>
      <div>
        <Typography variant="h5" ><strong>{total}</strong></Typography>
      </div>
      <div className={classes.fileDescription}>{t(`${type}` + '_FILES')}</div>
    </div>
  </div>;
}

ResultBlock.propTypes = propTypes;
