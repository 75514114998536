import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { LogoutIcon } from 'common/Icons';
import { getServerError } from 'pages/pages.utils';
import makeStyles from './LogoutButton.styles';
import { useAuth } from 'react-oidc-context';
import { ROUTE_PATHS } from 'utils/routes.constants';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles();

export default function LogoutButton () {
  const { signoutSilent } = useAuth();
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleOnLogout = async () => {
    try {
      signoutSilent();

      // Redirect to the "home" (scan) page
      navigate(ROUTE_PATHS.SCAN);
    } catch (error) {
      console.error(getServerError(error));
    }
  };

  return (
    <IconButton onClick={handleOnLogout}>
      <LogoutIcon className={classes.logoutIcon} title={t('logout')} />
    </IconButton>
  );
}
