import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  root: {
    position: 'relative',
    marginRight: 20,
    marginTop: 70
  },
  backgroundGauge: {
    color: 'rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    top: 0,
    left: 0
  },
  valueText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -130%)',
    zIndex: 1
  },
  label: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -60%)',
    zIndex: 1
  },
  warningBackground: {
    backgroundColor: '#fa4b5430',
    color: '#fa4b5430'
  }
}));
