import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteURL } from 'entities/urls/urls.api';
import { startURLAnalysisRequest } from 'entities/analysisRequest/analysisRequest.api';
import { URLGlobe } from 'common/Icons';
import DialogButtons from 'components/DialogButtons';
import MenuActions from 'components/MenuActions';
import DeleteUrlContent from '../DeleteUrlContent/DeleteUrlContent';
import { useDialog } from 'hooks';
import { useAuth } from 'react-oidc-context';
import { useSnackSetState } from 'contexts/SnackContext';
import { getHighestRole } from 'utils/roles';
import { MENU_KEYS } from './CellMenu.constants';
import propTypes from './CellMenu.propTypes';
import makeStyles from './CellMenu.style';

const useStyles = makeStyles();

export default function CellMenu ({
  url,
  onChange
}) {
  const [t, i18n] = useTranslation();
  const classes = useStyles();
  const setSnack = useSnackSetState();
  const auth = useAuth();

  const options = useMemo(() => {
    const highestRole = getHighestRole(auth?.user?.profile?.roles);
    return [
      {
        key: MENU_KEYS.RESCAN,
        placeholder: t('rescan'),
        roles: ['analyst', 'admin', 'platform_admin'],
        visible: true,
        onClick: () => {
          startURLAnalysisRequest([url.id]).then(() => onChange({}));
        }
      },
      {
        key: MENU_KEYS.DELETE,
        placeholder: t('delete_url'),
        roles: ['admin', 'platform_admin'],
        visible: true,
        onClick: async () => {
          onDisplayDeleteDialog({ isOpen: true });
        }
      }
    ].filter(option => option.roles.includes(highestRole.roleName) && option.visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url.id, i18n.language]);

  const handleDialogClose = () => {
    onDisplayDeleteDialog({ isOpen: false });
  };

  const onConfirmDeleteURL = async (fileId) => {
    try {
      const response = await deleteURL(fileId, { dry: false });
      if (response.status === 200) {
        setSnack({
          isOpen: true,
          title: t('url_deleted_successfully'),
          severity: 'success'
        });
      }
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: t('url_could_not_be_deleted'),
        severity: 'warning'
      });
    }
    onChange({});
  };

  const onDisplayDeleteDialog = useDialog(`delete-url-dialog-${url.id}`, {
    onClose: handleDialogClose,
    components: {
      Icon: <URLGlobe className={classes.dialogIconSize} color="#000000" />,
      Content: <DeleteUrlContent urlId={url.id}/>,
      Actions: (
      <div className= {classes.deleteButtonsWrapper}>
       <DialogButtons
        confirmLabel={t('yes_delete_url')}
        cancelLabel={t('no')}
        onConfirm={() => onConfirmDeleteURL(url.id)}
        onCancel={handleDialogClose}
        />
      </div>
      )
    }
  });

  return (
    options.length > 0
      ? <MenuActions options={options} />
      : null
  );
}

CellMenu.propTypes = propTypes;
