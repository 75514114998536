import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchProbe } from 'entities/probes/probes.api';
import { getDockerImageTag } from 'utils';
import PropTypes from 'prop-types';

export default function UpdateProbeContent ({ probe }) {
  const [t] = useTranslation();
  const [dockerImage, setDockerImage] = useState('');

  const getProbe = async () => {
    try {
      const response = await fetchProbe(probe.latest?.id);
      if (response.status === 200) {
        const dockerImg = getDockerImageTag(response.data);
        setDockerImage(dockerImg || 'N/A');
      }
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    getProbe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <p><strong>{t('version')}: </strong>{probe.latest?.version.software || 'N/A'}</p>
      <p><strong>{t('database_version')}: </strong>{probe.latest?.version.database || 'N/A'}</p>
      <p><strong>{t('docker_image')}: </strong>{dockerImage}</p>
    </div>
  );
}

UpdateProbeContent.propTypes = {
  probe: PropTypes.object
};
