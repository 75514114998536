import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ButtonGroup, Grid, Typography } from '@material-ui/core';
import ButtonFilter from './components/ButtonFilter';
import { propTypes } from './ResultsSummary.props';
import ResultBlock from './components/ResultBlock';

export default function ResultsSummary ({ files }) {
  const [t] = useTranslation();
  const locale = i18next.language;
  const [filesData, setFilesData] = useState([]);
  const [period, setPeriod] = useState(7);
  const [sincePeriod, setSincePeriod] = useState('');
  const maxPeriod = new Date();

  maxPeriod.setDate(maxPeriod.getDate() - period);

  const getdateFormat = (date) => {
    if (period === 1) {
      const hourlyDate = new Date(date);
      hourlyDate.setMinutes(0);
      hourlyDate.setSeconds(0);
      return hourlyDate.toLocaleString(locale, { hour: 'numeric' });
    } else if (period === 365) {
      return new Date(date).toLocaleString(locale, { month: 'long', year: '2-digit' });
    } else {
      return new Date(date).toLocaleString(locale, { day: 'numeric', month: 'short' });
    }
  };

  const loadData = () => {
    const lastFiles = files.filter(scan => {
      const scanDate = new Date(scan.date);
      return scanDate >= maxPeriod && scan.current_analysis;
    }).map((scan) => {
      return {
        id: scan.id,
        date: getdateFormat(scan.date),
        safety: scan.current_analysis.safety ?? scan.current_analysis.status,
        threatScore: scan.current_analysis.risk_score || null
      };
    });

    const groupedByThreatScore = lastFiles.reduce((prev, curr) => {
      const threatScore = curr.threatScore;
      if (threatScore !== null && !isNaN(threatScore)) {
        let group;
        if (threatScore >= 75 && threatScore <= 100) {
          group = 'HIGH';
        } else if (threatScore >= 60 && threatScore <= 74) {
          group = 'ELEVATED';
        } else if (threatScore >= 10 && threatScore <= 59) {
          group = 'MODERATE';
        } else {
          group = 'LOW';
        }
        if (!prev[group]) {
          prev[group] = 0;
        }
        prev[group]++;
      }
      return prev;
    }, {});

    setFilesData(groupedByThreatScore);
    setSincePeriod(maxPeriod.toISOString());
  };

  useEffect(() => {
    if (files.length > 0) {
      loadData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, period]);

  return <>
    <div>
      <Grid container justify='space-between'>
        <Grid item>
          <Typography variant="h5" component="span" gutterBottom><strong>{t('results')}</strong></Typography>
        </Grid>
        <Grid item>
          <ButtonGroup size='small' color='secondary'>
            <ButtonFilter id={1} period={period} setPeriod={setPeriod}>{t('last_24h')}</ButtonFilter>
            <ButtonFilter id={7} period={period} setPeriod={setPeriod}>{t('last_week')}</ButtonFilter>
            <ButtonFilter id={31} period={period} setPeriod={setPeriod}>{t('last_month')}</ButtonFilter>
            <ButtonFilter id={365} period={period} setPeriod={setPeriod}>{t('last_year')}</ButtonFilter>
          </ButtonGroup>
        </Grid>
      </Grid>
      <ResultBlock type='HIGH' total={filesData.HIGH ?? 0} since={sincePeriod} />
      <ResultBlock type='ELEVATED' total={filesData.ELEVATED ?? 0} since={sincePeriod} />
      <ResultBlock type='MODERATE' total={filesData.MODERATE ?? 0} since={sincePeriod} />
      <ResultBlock type='LOW' total={filesData.LOW ?? 0} since={sincePeriod} />
    </div>
  </>;
};

ResultsSummary.propTypes = propTypes;
