import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Header from './components/Header/Header';
import TabPanel from './components/TabPanel';
import ArchivesSidebar from './components/ArchivesSidebar';
import { useGetTargetData } from './Details.hooks';
import { getTabsSorted } from './Details.utils';
import { ROUTE_PATHS } from 'utils/routes.constants';
import { TYPES } from './Details.constants';
import makeStyles, { StyledScrollButton } from './styles';

const useStyles = makeStyles();

export default function Details () {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const prevSearchQuery = state?.prevSearchQuery;
  const { isFetching, targetDetails, targetResults, isFile, showArchives, archives, analysisWorkflow, fetchTargetData } = useGetTargetData();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const iconProps = { size: '15' };
  const isFilesDetail = pathname.includes('/file/');
  const scanStatus = targetDetails?.current_analysis?.status;

  const tabs = useMemo(() => !isFetching
    ? getTabsSorted(targetResults, targetDetails, analysisWorkflow)
    : [], [targetResults, targetDetails, analysisWorkflow, isFetching]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (!isFetching) {
      setLoading(false);
    };
  }, [targetDetails, isFetching]);

  useEffect(() => {
    if (scanStatus === 'RUNNING') {
      const intervalId = setInterval(() => { fetchTargetData(); }, 60000);
      return () => { clearInterval(intervalId); };
    }
  }, [scanStatus, fetchTargetData]);

  const probeTabs = tabs.map((tab, index) => {
    return (
      <Tab
        key={tab.label}
        label={
          <span className={classes.tabLabelAndIcon}>{<tab.iconComponent {...iconProps}/>}{t(tab.label)}</span>
        }
        id={`scrollable-auto-tab-${index + 1}`}
        aria-controls={`scrollable-auto-tabpanel-${index + 1}`}
      />
    );
  });

  const probeDetailsPanelList = tabs.map((tab, index) => (
    <TabPanel value={activeTab} key={tab.label} index={index} className={classes.tab}>
      <tab.component {...tab.props}/>
    </TabPanel>
  ));

  const backToSummary = () => {
    if (prevSearchQuery) {
      prevSearchQuery.back = true;
    };
    if (showArchives) {
      archives.parent.type === TYPES.FILE ? navigate(ROUTE_PATHS.FILES, { state: prevSearchQuery }) : navigate(ROUTE_PATHS.URL_ANALYSIS);
    } else {
      isFilesDetail ? navigate(ROUTE_PATHS.FILES, { state: prevSearchQuery }) : navigate(ROUTE_PATHS.URL_ANALYSIS, { state: prevSearchQuery });
    };
  };

  const buttonText = t('back');

  if (loading) {
    return <div className={classes.totalLoadProgress}><CircularProgress /></div>;
  }

  if (!loading) {
    return (
      <Grid className={!showArchives && classes.defaultPageLayout}>
        <Grid >
          <Button
            onClick={backToSummary}
            startIcon={<ArrowBackIcon />}
            className={showArchives && classes.archiveBackButton}
          >
            {buttonText}
          </Button>
        </Grid>
        <Grid container>
          {showArchives && <ArchivesSidebar archives={archives} setActiveTab={setActiveTab} />}
          <Grid item xs={showArchives ? 8 : 12}>
            <AppBar
              position="static"
              color="default"
              className={classes.detailsAppBar}
            >
              {!isFetching && <Header isFile={isFile} target={targetDetails} results={targetResults} workflow={analysisWorkflow} fetchTargetData={fetchTargetData}/>}
              <Tabs
                value={activeTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                ScrollButtonComponent={StyledScrollButton}
              >
                {probeTabs}
              </Tabs>
            </AppBar>
            {probeDetailsPanelList}
            {isFetching &&
              <div className={classes.centerProgress}>
                <CircularProgress />
              </div>
            }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
