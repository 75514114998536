import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { TextField, Grid, Button, MenuItem, FormControl, Select, InputLabel, Checkbox, ListItemText } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { fetchTags } from 'entities/tags/tags.api';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import { isString } from 'utils/types';
import { ANALYSIS_STATUS } from 'entities/analysis/analysis.constants';
import { buildFetchQuery, formatDateSince, formatDateUntil } from './AdvancedSearch.utils';
import { isAdvanced } from 'entities/auth/auth.utils';
import { propTypes } from './AdvancedSearch.props';
import { INITIAL_SEARCH_QUERY } from 'pages/MyFiles/MyFiles.constants';
import makeStyles from './AdvancedSearch.styles';

const useStyles = makeStyles();

const today = Date();
const initialQuery = INITIAL_SEARCH_QUERY.ADVANCED;

const AdvancedSearch = ({ onSearch, onReset, prevQuery }) => {
  const [t, i18n] = useTranslation();
  const classes = useStyles();
  const [searchState, setSearchState] = useState(initialQuery);
  const [tags, setTags] = useState();
  const isAdvancedUser = isAdvanced();

  useEffect(() => {
    if (isAdvancedUser) {
      const fetchTagsData = async () => {
        try {
          const response = await fetchTags();
          setTags(response.data);
        } catch (error) {
          console.error('Error fetching tags:', error);
        }
      };
      fetchTagsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localeMap = {
    en: enLocale,
    fr: frLocale
  };

  useEffect(() => {
    Object.entries(prevQuery).forEach(([key, value]) => {
      setSearchState((prevState) => ({
        ...prevState,
        [key]: (key === 'tags' && isString(value) ? value.replace(/(,$)/g, '').split(',') : value)
      }));
    });
  }, [prevQuery]);

  const handleOnSearch = () => onSearch(buildFetchQuery(searchState));
  const handleOnReset = () => {
    setSearchState(initialQuery);
    onReset(buildFetchQuery(initialQuery));
  };

  // Selectors and input handlers
  const handleChange = (e) => {
    setSearchState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleFromDateChange = (date) => {
    setSearchState((prevState) => ({
      ...prevState,
      since: formatDateSince(date)
    }));
  };

  const handleToDateChange = (date) => {
    setSearchState((prevState) => ({
      ...prevState,
      until: formatDateUntil(date)
    }));
  };

  const getMenuProps = () => ({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  });

  return (
    <Form
      onSubmit={handleOnSearch}
      render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} noValidate>
        <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        >
          <Grid container spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={classes.row}>
              <Grid item>
              <FormControl
                  variant="outlined"
                  className={classes.statuSelector}
              >
                <Select
                    id="main-selector"
                    value={searchState.main}
                    onChange={handleChange}
                    variant="outlined"
                    name="main"
                    className={classes.mainSelector}
                    MenuProps={getMenuProps()}
                >
                    <MenuItem value="name">{t('by_name')}</MenuItem>
                    <MenuItem value="hash">{t('by_hash')}</MenuItem>
                    <MenuItem value="threat">{t('by_threat_name')}</MenuItem>
                </Select>
              </FormControl>
              </Grid>
              <Grid item>
              <TextField
                name="searchText"
                label={t('search')}
                variant="outlined"
                onChange={handleChange}
                className={classes.searchText}
                size="medium"
                value={searchState?.searchText}
              />
              </Grid>
              <Grid item>
              {/* <FormControl
                  variant="outlined"
                  className={classes.statuSelector}
              >
                  <InputLabel htmlFor="safety-selector">{t('safety')}</InputLabel>
                  <Select
                  labelId="safety-selector"
                  id="safety-selector"
                  value={searchState.safety}
                  onChange={handleChange}
                  variant="outlined"
                  name="safety"
                  label={t('safety')}
                  MenuProps={getMenuProps()}
                  >
                    <MenuItem value="">{t('any')}</MenuItem>
                    <MenuItem value={SAFETY.SAFE.toUpperCase()}>{t('safety_safe')}</MenuItem>
                    <MenuItem value={SAFETY.UNSAFE.toUpperCase()}>{t('malicious')}</MenuItem>
                    <MenuItem value={SAFETY.CLEAN.toUpperCase()}>{t('clean')}</MenuItem>
                    <MenuItem value={SAFETY.SUSPICIOUS.toUpperCase()}>{t('safety_suspicious')}</MenuItem>
                  </Select>
              </FormControl> */}
              </Grid>
          </Grid>
          <Grid container spacing={3}
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.row}>
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
                <DatePicker
                  clearable
                  disableToolbar
                  okLabel={t('ok')}
                  cancelLabel={t('cancel')}
                  clearLabel={t('clear')}
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="date-picker-inline-from"
                  label= {t('from')}
                  value={searchState.since ? searchState.since : null}
                  maxDate={searchState.until ? searchState.until : today}
                  onChange={handleFromDateChange}
                  className={classes.dataPicker}
                  autoOk={true}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
                <DatePicker
                  clearable
                  disableToolbar
                  okLabel={t('ok')}
                  cancelLabel={t('cancel')}
                  clearLabel={t('clear')}
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="date-picker-inline-to"
                  label= {t('to')}
                  value={searchState.until ? searchState.until : null}
                  minDate={searchState.since ? searchState.since : null}
                  maxDate={today}
                  onChange={handleToDateChange}
                  className={classes.dataPicker}
                  autoOk={true}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <FormControl
                  variant="outlined"
                  className={classes.statuSelector}
              >
                <InputLabel htmlFor="status-selector">{t('status')}</InputLabel>
                <Select
                  labelId="status-selector"
                  id="status-selector"
                  value={searchState.status}
                  onChange={handleChange}
                  variant="outlined"
                  name="status"
                  label={t('status')}
                  className={classes.mainSelector}
                  MenuProps={getMenuProps()}
                >
                  <MenuItem value="">{t('any')}</MenuItem>
                  <MenuItem value={ANALYSIS_STATUS.FINISHED}>{t('status_finished')}</MenuItem>
                  <MenuItem value={ANALYSIS_STATUS.RUNNING}>{t('status_running')}</MenuItem>
                  <MenuItem value={ANALYSIS_STATUS.PENDING}>{t('status_pending')}</MenuItem>
                  <MenuItem value={ANALYSIS_STATUS.FAILED}>{t('status_failed')}</MenuItem>
                  <MenuItem value={ANALYSIS_STATUS.CANCELED}>{t('status_canceled')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {isAdvancedUser && <Grid item>
                <FormControl variant="outlined" className={classes.tagSelector}>
                  <InputLabel htmlFor="tag-selector">{t('tags')}</InputLabel>
                  <Select
                    labelId="tag-selector"
                    id="tag-selector"
                    value={searchState.tags || []}
                    onChange={handleChange}
                    variant="outlined"
                    name="tags"
                    label={t('tags')}
                    className={classes.mainSelector}
                    multiple
                    renderValue={(selected) => selected.map(tagId => tags?.find(tag => tag.id === tagId)?.text).join(', ')}
                    MenuProps={getMenuProps()}
                  >
                    <MenuItem value="">{t('any')}</MenuItem>
                    {tags?.map((tag) => (
                      <MenuItem key={tag.id} value={tag.id}>
                      <Checkbox checked={searchState.tags.includes(tag.id)} />
                      <ListItemText primary={tag.text} />
                    </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              }
            <Grid item>
              <FormControl
                variant="outlined"
                className={classes.row}
              >
                <InputLabel htmlFor="sort-selector">{t('sort')}</InputLabel>
                <Select
                  labelId="sort-selector"
                  id="sort-selector"
                  value={searchState.sort === 'updated:desc' ? '' : searchState.sort}
                  onChange={handleChange}
                  variant="outlined"
                  name="sort"
                  label={t('sort')}
                  className={classes.mainSelector}
                  MenuProps={getMenuProps()}
                >
                  <MenuItem value="name:asc">{t('name_az')}</MenuItem>
                  <MenuItem value="name:desc">{t('name_za')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {t('search')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleOnReset}
                className={`${classes.button} ${classes.outlinedButton}`}
              >
              {t('reset_search')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      )}
    />
  );
};

AdvancedSearch.propTypes = propTypes;
export default AdvancedSearch;
