import { makeStyles } from '@material-ui/core/styles';

const containerWidth = 400;
const linkCommonStyle = {
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'none'
  }
};

export default theme => makeStyles(theme => ({
  action: {
    position: 'fixed',
    bottom: '30px',
    width: `${containerWidth}px`,
    left: `calc(50% - ${containerWidth / 2}px)`,
    zIndex: 999,
    height: '44px',
    background: '#fff',
    display: 'flex',
    padding: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
    borderRadius: '5px',
    boxSizing: 'content-box'
  },
  info: {
    marginRight: '20px'
  },
  deleteLink: {
    ...linkCommonStyle,
    color: theme.palette.primary.main
  },
  cancelLink: {
    ...linkCommonStyle,
    color: theme.palette.grey[500]
  },
  divider: {
    color: theme.palette.grey[500],
    margin: '0 20px'
  }
}));
