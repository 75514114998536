import React, { useEffect, useState } from 'react';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import Loading from 'common/Loading';
import { ROUTE_PATHS } from '../../utils/routes.constants.js';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function HomePage () {
  const { isLoading: isAppConfigLoading } = useAppConfigContext();
  const { isLoading: isAuthLoading } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const [overallLoading, setOverallLoading] = useState(true);

  useEffect(() => {
    if (!isAppConfigLoading && !isAuthLoading) {
      setOverallLoading(false);
    }
  }, [isAppConfigLoading, isAuthLoading]);

  if (overallLoading) {
    return <div className={classes.loaderContainer}><Loading size={80} /></div>;
  }

  return navigate(ROUTE_PATHS.SCAN);
}
