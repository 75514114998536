import React from 'react';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useLocation, NavLink } from 'react-router-dom';
import SidebarText from './SidebarText';
import SidebarIcon from './SidebarIcon';
import { UsersIcon } from 'common/Icons';
import {
  List,
  ListElement,
  ListElementIcon
} from 'components/List';
import routes from 'utils/getRoutes';
import { getHighestRole } from 'utils/roles';
import { isPlatformAdmin } from 'entities/auth/auth.utils';
import { ROUTE_PATHS } from 'utils/routes.constants';
import PropTypes from 'prop-types';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function NavigationSidebar ({ collapse = true }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const appConfig = useAppConfigContext();
  const location = useLocation();
  const auth = useAuth();
  const highestRole = getHighestRole(auth?.user?.profile?.roles);
  const isPlatformAdminUser = isPlatformAdmin();

  const sidebar = routes.filter(route => {
    return route.sidebar(appConfig) && (route.roles && route.roles.includes(highestRole.roleName));
  });

  const apiEndpoint = window._conf_.QUARKSFLOW_API;
  const adminEndpoint = apiEndpoint.slice(0, -3) + ROUTE_PATHS.KEYCLOAK_ADMIN;
  const iconProps = {
    style: {
      width: '1.5rem',
      height: '1.5rem'
    }
  };

  return (
    <List>
      {
        sidebar.map(({ path, text, id }) => (
          <NavLink key={path} to={path} className={classes.routeLink}>
            <ListElement button selected={path === location.pathname}>
              <ListElementIcon style={{ minWidth: 35 }}>
                <SidebarIcon sidebarId={id} />
              </ListElementIcon>
              {collapse ? null : <SidebarText text={text} />}
            </ListElement>
          </NavLink>
        ))
      }
      {isPlatformAdminUser &&
        (
          <ListElement button component="a" href={adminEndpoint} target="_blank" rel="noopener noreferrer" className={classes.newTabLink}>
            <ListElementIcon style={{ minWidth: 35 }}>
            <UsersIcon {...iconProps} />
            </ListElementIcon>
            {collapse ? null : <SidebarText text= {t('accounts_administration')}/>}
          </ListElement>
        )
      }
    </List>
  );
}

NavigationSidebar.propTypes = {
  collapse: PropTypes.bool
};
