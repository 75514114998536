import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { UploadIcon } from 'common/Icons';
import makeStyles from './styles';
import { isUserLogged } from 'entities/auth/auth.utils';

const useStyles = makeStyles();

export const DragActiveTypography = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div>
        <Typography variant="body1" gutterBottom className={classes.dropzoneSmallText}>
          {t('drop_your_files_here')}
        </Typography>
      </div>
    </>
  );
};

export const DropZoneTypography = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const isLogged = isUserLogged();
  const guestLimitText = t('guest_limit');
  const supportFileText = t('we_support_all_file_types') + (isLogged ? '' : ' ' + guestLimitText);
  const guestDropFileText = t('drop_the_file_here_or_click_to_upload');
  const loggedUserDropFileText = t('drop_the_files_here_or_click_to_upload');
  const dropFileText = isLogged ? loggedUserDropFileText : guestDropFileText;

  return (
    <>
      <div>
        <UploadIcon className={classes.uploadIconSvg} title={t('drop_the_files_here')}/>
        <Typography variant="h6" gutterBottom className={classes.dropzoneBigText}>
          { dropFileText }
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.dropzoneSmallText}>
          { supportFileText }
        </Typography>
      </div>
    </>
  );
};

export default DropZoneTypography;
