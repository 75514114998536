import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { fetchFile, fetchFileLatestResult } from 'entities/files/files.api';
import { fetchAnalysis } from 'entities/analysis/analysis.api';
import { fetchURL } from 'entities/urls/urls.api';
import { TYPES } from './Details.constants';
import { fetchArchivesAnalysis } from 'entities/analysisRequest/analysisRequest.api';
import { ROUTE_PATHS } from 'utils/routes.constants';

/**
 * The main reason for this hook is to hide the complexisty of getting URLs and Files details.
 * This bridge will just return two types of data: targetDetails, and targetResults
 * It could be for a file, or for a URL our caller should not worry about it.
 * If there are descrepencies, they should be handled here, the returned structure should be the same.
 * A boolean `isFile` is returned to be used in cases where we want to differentiate.
 */
export const useGetTargetData = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [targetResults, setTargetResults] = useState({});
  const [targetDetails, setTargetDetails] = useState({});
  const [showArchives, setShowArchives] = useState(false);
  const [analysisWorkflow, setAnalysisWorkflow] = useState('default');

  const [archives, setArchives] = useState();
  const isFile = pathname.includes('/details/file/');
  const navigate = useNavigate();

  const getFileAnalysisData = async () => {
    setIsFetching(true);
    try {
      const [fileResult, latestResult] = await Promise.all([fetchFile(id), fetchFileLatestResult(id)]);
      setTargetDetails({ ...fileResult.data, type: TYPES.FILE });
      setTargetResults(latestResult.data);

      const res = await fetchArchivesAnalysis({ ...fileResult.data, type: TYPES.FILE });
      setAnalysisWorkflow(res?.parent?.workflow);

      if (res.parent?.status && res.parent.status !== fileResult.data?.current_analysis?.status) {
        getFileData();
      }

      if (res.parent != null && res.children != null) {
        setArchives({ parent: res.parent, children: res.children });
        setShowArchives(true);
      }
    } catch (error) {
      if (error.response) {
        // Not a valid UUID
        if (error.response.status === 400) {
          navigate(ROUTE_PATHS.FILES, { state: { error: 'File not found' } });
        // The user does not have permission to access this file
        } else if (error.response.status === 404) {
          navigate(ROUTE_PATHS.FORBIDDEN);
        }
      }
    } finally {
      setIsFetching(false);
    }
  };

  const getFileData = async () => {
    Promise.all([fetchFile(id), fetchFileLatestResult(id)])
      .then(results => {
        setTargetDetails({ ...results[0].data, type: TYPES.FILE });
        setTargetResults(results[1].data);
      });
  };

  const getURLData = async () => {
    setIsFetching(true);
    try {
      const urlData = await fetchURL(id);
      const analysisId = urlData?.data?.current_analysis?.id;
      const analysisData = await fetchAnalysis(analysisId);

      const res = await fetchArchivesAnalysis({ ...urlData.data, type: TYPES.URL, name: urlData.data.url });
      setAnalysisWorkflow(res?.parent?.workflow);
      if (res.parent != null && res.children != null) {
        setArchives({ parent: res.parent, children: res.children });
        setShowArchives(true);
      }
      setTargetDetails({ ...urlData.data, type: TYPES.URL });
      setTargetResults(analysisData?.data?.results);
    } catch (error) {
      if (error.response) {
        // Not a valid UUID
        if (error.response.status === 400) {
          navigate(ROUTE_PATHS.URL_ANALYSIS, { state: { error: 'URL not found' } });
        // The user does not have permission to access this file
        } else if (error.response.status === 404) {
          navigate(ROUTE_PATHS.FORBIDDEN);
        }
      }
    } finally {
      setIsFetching(false);
    }
  };

  const fetchTargetData = () => isFile ? getFileAnalysisData() : getURLData();

  useEffect(() => {
    fetchTargetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return {
    isFetching,
    targetDetails,
    targetResults,
    isFile,
    archives,
    showArchives,
    fetchTargetData,
    analysisWorkflow
  };
};
