const UI_PREFIX = '/ui';

export const ROUTE_PATHS = {
  HOMEPAGE: '/',
  DASHBOARD: `${UI_PREFIX}/dashboard`,
  SCAN: `${UI_PREFIX}/scan`,
  FILES: `${UI_PREFIX}/files`,
  RESULTS_FILE: `${UI_PREFIX}/results/file`,
  RESULTS_URL: `${UI_PREFIX}/results/url`,
  URL_ANALYSIS: `${UI_PREFIX}/url-analysis`,
  PROBES: `${UI_PREFIX}/probes`,
  PROBE_MANAGEMENT: `${UI_PREFIX}/probes/:store/:id`,
  DETAILS_FILE: `${UI_PREFIX}/details/file/:id`,
  DETAILS_URL: `${UI_PREFIX}/details/url/:id`,
  SIGNIN: `${UI_PREFIX}/signin`,
  SIGNUP: `${UI_PREFIX}/signup`,
  USER_SETTINGS: `${UI_PREFIX}/user-settings`,
  URL_SCAN: `${UI_PREFIX}/scan?tab=url`,
  TAGS: `${UI_PREFIX}/tags`,
  FORBIDDEN: `${UI_PREFIX}/forbidden`,
  SETTINGS: `${UI_PREFIX}/settings`,
  KEYCLOAK_ADMIN: '/admin/qflow-default/console'
};
