import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { InfoIcon } from 'common/Icons';
import GenericDialog from '../GenericDialog';
import Safety from 'pages/components/Safety';
import Security from 'pages/components/Security';
import Privacy from 'pages/components/Privacy';
import ProbeStatus from './components/ProbeStatus';
import { isNotNull } from 'utils/types';
import CellFallbackView from '../CellFallbackView';
import { getRows } from './SummaryTable.utils';
import { propTypes } from './SummaryTable.props';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const SummaryTable = ({ data = [], workflow }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const rows = useMemo(() => getRows(data), [data]);
  const [showApkQualifiers, setShowApkQualifiers] = useState(false);

  const cellProps = {
    className: classes.cell,
    component: 'th',
    scope: 'row'
  };

  useEffect(() => {
    if (workflow === 'apk-scan') {
      setShowApkQualifiers(true);
    }
  }, [workflow]);

  return (
    <Grid>
      <Grid item>
        <Table className={classes.summaryTable} size="medium" aria-label={t('threats')}>
          <TableHead>
              <TableCell className={classes.headerCell} align="left" >{t('probe')}</TableCell>
              <TableCell className={classes.headerCell} align="left" >{t('category')}</TableCell>
              <TableCell className={classes.headerCell} align="center" >{t('status')}</TableCell>
              <TableCell className={classes.headerCell} align="left" >{t('duration')}</TableCell>
              <TableCell className={classes.headerCell} align="left" >{t('threat_level')}</TableCell>
              {showApkQualifiers && <>
                <TableCell className={classes.headerCell} align="left" >{t('security')}</TableCell>
                <TableCell className={classes.headerCell} align="left" >{t('privacy')}</TableCell>
              </>}
              <TableCell className={classes.headerCell} align="left" >{t('threat_name')}</TableCell>
          </TableHead>
          <TableBody>
            {rows.map(({ id, probeName, probeCategory, probeVersion, status, error, duration, SafetyQualifier, SecurityQualifier, PrivacyQualifier, ThreatLabels }) => {
              return (
                <TableRow key={id}>
                  <TableCell {...cellProps} style={{ whiteSpace: 'nowrap' }}>{probeName}
                  <GenericDialog id={`${id}-${probeVersion.software}`}
                      label={
                      <Tooltip title={t('click_for_version')} arrow>
                        <IconButton>
                          <InfoIcon style={{ width: 14, height: 14, color: 'dark grey' }} />
                        </IconButton>
                      </Tooltip>
                    } data={probeVersion} />
                  </TableCell>
                  <TableCell {...cellProps}>{probeCategory}</TableCell>
                  <TableCell {...cellProps} align='center' ><ProbeStatus status={status} error={error} id={id}/></TableCell>
                  <TableCell {...cellProps}>{(duration !== 0 ? duration + 's' : null)}</TableCell>
                  <CellFallbackView {...cellProps} condition={isNotNull(SafetyQualifier)} className={classes.customCell}>
                    <Safety rowData={{ current_analysis: { safety: SafetyQualifier } }} />
                  </CellFallbackView>
                  {showApkQualifiers && <>
                    <CellFallbackView {...cellProps} condition={isNotNull(SecurityQualifier)} className={classes.customCell}>
                      <Security rowData={{ current_analysis: { security: SecurityQualifier } }} />
                    </CellFallbackView>
                    <CellFallbackView {...cellProps} condition={isNotNull(PrivacyQualifier)} className={classes.customCell}>
                      <Privacy rowData={{ current_analysis: { privacy: PrivacyQualifier } }} />
                    </CellFallbackView>
                  </>
                  }
                  <CellFallbackView {...cellProps} condition={isNotNull(ThreatLabels)}>
                  {ThreatLabels.join(', ')}
                  </CellFallbackView>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

SummaryTable.propTypes = propTypes;

export default SummaryTable;
