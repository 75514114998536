import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Typography, Tooltip } from '@material-ui/core';
import { useAuth } from 'react-oidc-context';
import { propTypes } from './NameColumn.props';
import { ROUTE_PATHS } from 'utils/routes.constants';

const Filename = ({ rowData }) => {
  const auth = useAuth();
  return auth?.user?.profile?.roles && auth?.user?.profile?.roles.length > 0
    ? (
        <Tooltip
          title='Go to results'
          className="tooltip"
          arrow
        >
          <Link to={generatePath(ROUTE_PATHS.DETAILS_URL, { id: rowData.id })}>{rowData.url}</Link>
        </Tooltip>
      )
    : (
        <Typography>
          {rowData.url}
        </Typography>
      );
};

Filename.propTypes = propTypes;

export default Filename;
