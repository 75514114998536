import HTTP from 'common/http';

const CATALOGS = 'catalogs';
export const DEFAULT_CATALOG = 'catalog.1';

export const fetchCatalogs = params => {
  const url = CATALOGS;
  return HTTP().get(url, params);
};

export const fetchCatalog = (id) => {
  const url = `${CATALOGS}/${id}`;
  return HTTP().get(url);
};

export const fetchCatalogProbes = (catalogId, params) => {
  const url = `${CATALOGS}/${catalogId}/browse?sort=name`;
  return HTTP().get(url, params);
};

export const fetchRemoteProbe = (catalogId, name, image) => {
  const url = `${CATALOGS}/${catalogId}/${name}/${image}`;
  return HTTP().get(url);
};

export const registerProbe = (catalogId, image, tag) => {
  const url = `${CATALOGS}/${catalogId}/${image}/${tag}/register`;
  return HTTP().post(url);
};

export const refreshCatalog = (id, params = {}) => {
  const url = `${CATALOGS}/${id}/fetch`;
  return HTTP().post(url, { params });
};
