import axios from 'axios';
import { HTTP_METHODS } from './http.constants';
import { User } from 'oidc-client-ts';

function HTTP () {
  const getBaseURL = () => {
    return `${window._conf_.QUARKSFLOW_API}`;
  };

  function getUser () {
    const oidcStorage = localStorage.getItem(`oidc.user:${window._conf_.KEYCLOAK_AUTHORITY}:${window._conf_.KEYCLOAK_CLIENT_ID}`);
    if (!oidcStorage) {
      return null;
    }

    return User.fromStorageString(oidcStorage);
  };

  const getToken = () => {
    try {
      const user = getUser();
      const token = user?.access_token;
      return token ? `Bearer ${token}` : undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  const getHeaders = (additionalHeaders = {}) => {
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
      ...additionalHeaders,
      ...(token ? { Authorization: token } : {})
    };
    return headers;
  };

  const _sendRequest = (method, url, options = {}) => {
    const headers = getHeaders(options.headers);
    return axios({ baseURL: getBaseURL(), url, method, ...options, headers });
  };

  const sendRequestDontUseOutsideBridgeApi = (method, url, options) => {
    return _sendRequest(method, url, options);
  };

  const get = (url, options = {}) => {
    return _sendRequest(HTTP_METHODS.GET, url, options);
  };

  const post = (url, options = {}) => {
    return _sendRequest(HTTP_METHODS.POST, url, options);
  };

  const put = (url, options = {}) => {
    return _sendRequest(HTTP_METHODS.PUT, url, options);
  };

  const del = (url, options = {}) => {
    return _sendRequest(HTTP_METHODS.DELETE, url, options);
  };

  return {
    getBaseURL,
    get,
    post,
    put,
    del,
    sendRequestDontUseOutsideBridgeApi
  };
};

export default HTTP;
