import jwtDecode from 'jwt-decode';
import { User } from 'oidc-client-ts';

export const getUser = () => {
  const oidcStorage = localStorage.getItem(`oidc.user:${window._conf_.KEYCLOAK_AUTHORITY}:${window._conf_.KEYCLOAK_CLIENT_ID}`);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const getLoggedInUser = () => {
  const user = getUser();
  const token = user?.access_token;
  return token ? { ...jwtDecode(token) } : { roles: [] };
};

const getRoles = () => {
  const user = getLoggedInUser();
  return user.roles;
};

export const isAdmin = () => getRoles().some(role => ['admin', 'platform_admin'].includes(role));

export const isAnalyst = () => getRoles().includes('analyst');

export const isOperator = () => getRoles().includes('operator');

export const isPlatformAdmin = () => getRoles().includes('platform_admin');

export const isAdvanced = () => getRoles().some(role => ['analyst', 'admin', 'platform_admin'].includes(role));

export const getLoggedUserId = () => {
  const user = getLoggedInUser();
  return user.identity?.id;
};

export const isGuestUser = () => {
  const user = getLoggedInUser();
  const isGuestUser = user.roles && user.roles.length === 0;
  return isGuestUser;
};

export const isUserLogged = () => {
  const user = getUser();
  return !!user?.session_state;
};
