import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { propTypes } from './WithAuth.props';
import { ROUTE_PATHS } from 'utils/routes.constants';
import { isGuestUser } from 'entities/auth/auth.utils';

const RequireAuth = ({ children, redirectLocation = ROUTE_PATHS.SCAN }) => {
  const location = useLocation();
  const isGuest = isGuestUser();
  if (isGuest) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={redirectLocation} state={{ from: location }} replace/>;
  }

  return children;
};

RequireAuth.propTypes = propTypes;

export default RequireAuth;
