import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { propTypes } from './LastMaliciousFiles.props';
import { TableLocalization } from 'common/tableConfig';
import getTableProps from './LastMaliciousFiles.tableprops';
import makeStyles from './LastMaliciousFiles.style';

const useStyles = makeStyles();

export default function LastMaliciousFiles ({ files }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const [last5Files, setLast5Files] = useState([]);
  const localization = TableLocalization();
  const { columns, options, actions, components } = getTableProps();

  const loadData = () => {
    const lastFiles = files.filter(scan => {
      return scan.current_analysis !== null;
    }).map((scan) => {
      return {
        id: scan.id,
        date: scan.date,
        filename: scan.name,
        safety: scan.current_analysis.safety ?? scan.current_analysis.status,
        threat: scan.current_analysis.risk_score
      };
    });
    const last5MaliciousFiles = lastFiles.filter(scan => {
      return scan.threat !== null && scan.threat >= 75;
    }).slice(-5).reverse();

    setLast5Files(last5MaliciousFiles);
  };

  useEffect(() => {
    if (files.length > 0) {
      loadData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return <>
    <div>
      <Typography variant="h5" component="span" gutterBottom className={classes.header}><strong>{t('last_5_high_threat')}</strong></Typography>
      <MaterialTable
          title=''
          columns={columns}
          data={last5Files}
          options={options}
          actions={actions}
          components={components}
          localization={localization}
        />
    </div>
  </>;
};

LastMaliciousFiles.propTypes = propTypes;
