import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none'
  },
  fileIcon: {
    position: 'absolute',
    left: -20,
    width: 20,
    height: 20,
    marginRight: '10px'
  },
  loading: {
    position: 'absolute',
    left: -20,
    marginTop: '5px'
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'inline',
    marginLeft: '-20px'
  },
  fileName: {
    paddingLeft: '30px',
    paddingRight: '20px',
    marginRight: '30px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fileNameLoading: {
    paddingLeft: '30px',
    paddingRight: '20px',
    marginRight: '30px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tooltip: {
    color: '#FFFFFF',
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: 15,
    padding: 8
  },
  tooltipBackground: {
    backgroundColor: props => {
      const riskScore = props.riskScore;
      if (riskScore === null) return 'rgb(119, 119, 119)';

      if (riskScore >= 75) return 'rgb(250, 75, 84)';
      if (riskScore >= 60) return 'rgb(255, 161, 67)';
      if (riskScore >= 10) return 'rgb(48, 176, 67)';
      return 'rgb(22, 116, 36)';
    }
  }
}));
