import { ANTIVRUS, SAFETY_QUALIFIER, UNSAFE } from './Header.constants';

/**
 * Filter antivirus probes.
 * @param Array results
 * @returns Array
 */
export const getAvProbesResults = (results = []) => results.filter(entry => entry.probe?.category === ANTIVRUS);

/**
 * Results of type unsafe.
 * @param Array avs
 * @returns Array
 */
const filterUnsafeAvs = (avs = []) => avs.filter(result => result.type === SAFETY_QUALIFIER && result.safety === UNSAFE);

/**
 * The count of unsafe results.
 * @param Array avs list of antivirus results.
 * @returns Number
 */
export const getUnsafeAvsCount = (avs = []) => filterUnsafeAvs(avs)?.length ?? 0;

export const checkIsScanning = (target = {}) => {
  const safety = target?.current_analysis?.safety?.toLowerCase?.();
  const status = target?.current_analysis?.status?.toLowerCase?.();
  return !safety && (status === 'pending' || status === 'running');
};

export const checkIsNotRunning = (target = {}) => {
  const safety = target?.current_analysis?.safety?.toLowerCase?.();
  const status = target?.current_analysis?.status?.toLowerCase?.();
  return !safety && status === 'notAnalyzed';
};

export const checkIsFailed = (target = {}) => {
  const safety = target?.current_analysis?.safety?.toLowerCase?.();
  const status = target?.current_analysis?.status?.toLowerCase?.();
  return !safety && status === 'failed';
};
