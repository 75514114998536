import React from 'react';
import { MTableToolbar } from 'material-table';
import tableConfig from 'common/tableConfig';
import makeStyles from './styles';
import { getColumns } from './columns';

const useStyles = makeStyles();

export default (refreshTableData, searchQuery, isAdvancedUser) => ({
  columns: getColumns(refreshTableData, searchQuery, isAdvancedUser),
  components: {
    ...tableConfig.components,
    Toolbar: function CustomToolbar (props) {
      const classes = useStyles();
      return <MTableToolbar {...props}
        classes={{
          root: classes.root,
          actions: classes.actions,
          title: classes.title,
          searchField: classes.searchField
        }}
      />;
    }
  },
  options: {
    ...tableConfig.options,
    search: true,
    searchFieldAlignment: 'left',
    selection: true,
    cellStyle: {
      borderBottom: '1px solid #e0e0e0',
      padding: '0px'
    },
    actionsCellStyle: {
      width: '25%',
      maxWidth: '25%',
      border: 'none',
      display: 'flex',
      justifyContent: 'center'
    }
  }
});
