import _ from 'lodash';

export const rolesWithWeight = {
  platform_admin: {
    label: 'PlatformAdmin',
    roleName: 'platform_admin',
    weight: 1
  },
  admin: {
    label: 'Administrator',
    roleName: 'admin',
    weight: 2
  },
  analyst: {
    label: 'Analyst',
    roleName: 'analyst',
    weight: 3
  },
  operator: {
    label: 'Operator',
    roleName: 'operator',
    weight: 4
  }
};

const HIGHEST_WEIGHT = _.size(rolesWithWeight) + 1;

export const getWeight = (role) => {
  const matchedRole = rolesWithWeight[role];

  if (!matchedRole) {
    return HIGHEST_WEIGHT;
  }

  return matchedRole.weight;
};

export const getHighestRole = (roles) => {
  const sortedRoles = roles?.sort(
    (roleA, roleB) => getWeight(roleA) - getWeight(roleB)
  );

  const highestRole = sortedRoles?.[0];

  if (highestRole && rolesWithWeight[highestRole]) {
    return rolesWithWeight[highestRole];
  }

  return {
    label: 'None',
    roleName: 'none',
    weight: HIGHEST_WEIGHT
  };
};
