import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SaveIcon, Warning } from 'common/Icons';
import GenericDialog from 'pages/Details/components/GenericDialog';
import { propTypes } from './ProbeStatus.props';
import makeStyles from './ProbeStatus.styles';
import Loading from 'common/Loading';

const useStyles = makeStyles();

const ProbeStatus = ({ status, error, id }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const finished = (status === 'ok');
  const failed = (status === 'error' || status === 'warning');
  const requested = (status === 'requested');
  const otherStatus = !(finished || failed || requested);

  return (
    <>
      {finished &&
        <Tooltip title={t('probe_analyses_completed_successfully')} arrow>
          <div className={classes.noClickTooltip}>
            <SaveIcon className={classes.statusIcon} />
          </div>
        </Tooltip>
      }
      {failed && <GenericDialog id={id} label={<Tooltip title={t('click_for_error')} arrow>
        <IconButton>
          <Warning className={classes.statusIcon} />
        </IconButton>
      </Tooltip>} data={error} />
      }
      {requested &&
        <Loading size={12} />
      }
      {otherStatus &&
        <Tooltip title={status} arrow>
          <div className={classes.noClickTooltip} >
            <Warning className={classes.statusIcon} />
          </div>
        </Tooltip>
      }
    </>
  );
};

ProbeStatus.propTypes = propTypes;

export default ProbeStatus;
