import { WebStorageStateStore } from 'oidc-client-ts';

export const keycloakConfig = {
  authority: window._conf_.KEYCLOAK_AUTHORITY,
  client_id: window._conf_.KEYCLOAK_CLIENT_ID,
  client_secret: window._conf_.KEYCLOAK_CLIENT_SECRET,
  redirect_uri: window._conf_.KEYCLOAK_REDIRECT_URI,
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: true,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};
