import HTTP from 'common/http';

const TAGS = 'tags';

export const fetchTags = (params = {}) => {
  const url = TAGS;
  return HTTP().get(url, { params });
};

export const createTag = tag => {
  const url = `${TAGS}?tag=${tag}`;
  const headers = {
    'content-type': 'application/x-www-form-urlencoded'
  };
  return HTTP().post(url, { headers });
};

export const deleteTag = id => {
  const url = `${TAGS}/${id}`;
  return HTTP().del(url);
};
