import React, { useEffect, useState } from 'react';
import { LinearProgress, Typography, Chip } from '@material-ui/core';
import { ANALYSIS_STATUS } from 'entities/analysis/analysis.constants';
import { fetchAnalysis } from 'entities/analysis/analysis.api';
import useStyles from './ThreatScore.styles';
import { propTypes } from './ThreatScore.props';

function ThreatScore ({ rowData }) {
  const threatScore = rowData?.current_analysis?.risk_score || null;
  const classes = useStyles({ value: threatScore });

  const status = rowData.current_analysis ? rowData.current_analysis.status : null;
  const isScanning = status === ANALYSIS_STATUS.RUNNING || status === ANALYSIS_STATUS.PENDING;

  const [progress, setProgess] = useState('');
  useEffect(() => {
    // Compute the percentage of files processed so far
    computeProgress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to find maximum number of items and items with status RUNNING
  function findMaxAndRunningItems (data) {
    let runningItemsCount = 0;
    let maxItemsCount = 0;

    data.data?.results.forEach(result => {
      // Get the number of analysis -> Either EXTRACTED files or the probe
      if (result?.artifacts && result?.artifacts.length > 0) {
        result.artifacts?.forEach(artifact => {
          if (artifact?.type === 'EXTRACTED') {
            // Only count EXTRACTED files for the total
            maxItemsCount += 1;

            // Count running extracted files
            if (artifact?.current_analysis?.status === 'RUNNING') {
              runningItemsCount += 1;
            }
          }
        });
      } else if (result.status === 'requested') {
        // If the Probe is still in the 'requested' status then it means it has not finished yet
        runningItemsCount += 1;
      }

      // Count the probe itself
      maxItemsCount += 1;
    });

    return { maxItemsCount, runningItemsCount };
  }

  // Compute the progress of a scan
  async function computeProgress () {
    // Fetch the analysis of the file
    // So we can know how many artefact/files have been extracted
    let runningItems = 0;
    let totalItems = 0;
    try {
      const analysisId = rowData?.current_analysis?.id;
      const analysisResponse = await fetchAnalysis(analysisId);

      // Compute progress
      const result = findMaxAndRunningItems(analysisResponse);
      runningItems = result.runningItemsCount;
      totalItems = result.maxItemsCount;

      // Do not compute and display the little chip if no data is available to compute
      if (totalItems > 0) {
        const percentage = Math.trunc(100 * (totalItems - runningItems) / totalItems);

        // Sanity check
        (percentage >= 0 && percentage <= 100) ? setProgess(`${percentage}%`) : setProgess('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {isScanning && (
        <div>
          <Typography variant="body4">Scanning
            {progress && (
              <Chip style={{ marginLeft: 10 }} label={progress} size="small" aria-label="scanning progress"/>
            )}
          </Typography>
          <LinearProgress
            color="primary"
            classes={{
              bar: `${classes.bar}`,
              colorPrimary: classes.grey,
              root: classes.rootProgress
            }}
          />
        </div>
      )}
      {!isScanning && threatScore !== null && (
        <div>
          <Typography variant="body1">{threatScore}</Typography>
          <LinearProgress
            color="primary"
            classes={{
              bar: `${classes.bar} ${classes.barColor}`,
              colorPrimary: classes.grey,
              root: classes.rootProgress
            }}
            variant="determinate"
            value={threatScore}
          />
        </div>
      )}
      {!isScanning && threatScore === null && (
        <div>
          <Typography variant="body4">Unknown</Typography>
        </div>
      )}
    </div>
  );
}

ThreatScore.propTypes = propTypes;
export default ThreatScore;
