import HTTP from 'common/http';
import { fetchAnalysis } from 'entities/analysis/analysis.api';
import { ANALYSIS_REQUEST, TYPES } from './analysisRequest.constants';

export const fetchAnalysisRequest = (id, params) => {
  const url = `${ANALYSIS_REQUEST}/${id}`;
  return HTTP().get(url, { params });
};

const startAnalysisRequest = (type, items, options) => {
  const url = ANALYSIS_REQUEST;
  let data = null;

  if (type === TYPES.FILE) {
    data = { files: items };
  } else {
    data = { urls: items };
  }

  if (options) {
    data.options = options;
  } else {
    data.options = {
      force: false,
      priority: 'MEDIUM',
      workflow: 'default',
      workflow_options: {}
    };
  }

  return HTTP().post(url, { data });
};

export const startURLAnalysisRequest = async (items, options) => startAnalysisRequest(TYPES.URL, items, options);

export const startFileAnalysisRequest = async (items, options) => startAnalysisRequest(TYPES.FILE, items, options);

export const fetchAnalysisRequestId = async id => {
  const response = await fetchAnalysis(id);
  return response?.data?.request?.id;
};

export const fetchArchivesAnalysis = async targetDetails => {
  const archiveAnalysis = {};
  const targetAnalysis = targetDetails?.analysis?.[0];
  if (!targetAnalysis) {
    return archiveAnalysis;
  }

  const parentAnalysisRequestId = await fetchAnalysisRequestId(targetAnalysis.id);
  const parentAnalysis = await fetchAnalysisRequest(parentAnalysisRequestId);
  const archiveFolder = {
    id: targetDetails.id,
    safety: parentAnalysis?.data?.analysis[0]?.safety ?? targetAnalysis.safety,
    status: parentAnalysis?.data?.analysis[0]?.status ?? targetAnalysis.status,
    name: targetDetails.name,
    workflow: parentAnalysis?.data?.options?.workflow,
    type: targetDetails.type,
    riskScore: targetDetails.current_analysis?.risk_score
  };
  archiveAnalysis.parent = archiveFolder;

  const childrenAnalysisId = parentAnalysis?.data?.children[0]?.id;
  if (childrenAnalysisId) {
    const childrenAnalysis = await fetchAnalysisRequest(childrenAnalysisId);
    const children = childrenAnalysis?.data?.analysis?.map(child => ({
      id: child.target.id,
      safety: child.safety,
      name: child.target.name || child.target.url,
      riskScore: child.risk_score
    })) || [];

    archiveAnalysis.children = children;
  }
  return archiveAnalysis;
};
