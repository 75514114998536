import HTTP from 'common/http';

const URLS = 'urls';
const TAGS = 'tags';

export const fetchURLs = params => {
  const url = URLS;
  return HTTP().get(url, { params });
};

export const fetchURL = (id, params) => {
  const url = `${URLS}/${id}`;
  return HTTP().get(url, { params });
};

export const uploadURL = urlString => {
  const url = URLS;
  const data = { url: urlString, submitter: 'webui' };
  return HTTP().post(url, { data });
};

// TAGS
export const addTagToUrl = (id, params) => {
  const url = `${URLS}/${id}/${TAGS}`;
  return HTTP().put(url, { params });
};

export const deleteURL = (id, params) => {
  const url = `${URLS}/${id}/wipe`;
  return HTTP().post(url, { params });
};

export const deleteTagFromUrl = (id, params) => {
  const url = `${URLS}/${id}/${TAGS}`;
  return HTTP().del(url, { params });
};
