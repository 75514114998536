import React, { useState, createContext, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { fetchInstanceDetails } from 'entities/about/about.api';
import { fetchUrlAnalysisEnabled } from 'entities/readiness/readiness.api';
import { fetchSelfUser } from 'entities/users/users.api';
import { isDefined } from 'utils/types';
import PropTypes from 'prop-types';

const AppConfigContext = createContext();
const DEFAULT_SAAS_MODE_ENABLED = true;
const DEFAULT_URL_ANALYSIS_ENABLED = false;
const ANONYMOUS_USER_ID = 'anonymous';

const AppConfigProvider = ({ children }) => {
  const auth = useAuth();
  const [saasModeEnabled, setSaasModeEnabled] = useState(DEFAULT_SAAS_MODE_ENABLED);
  const [urlAnalysisEnabled, setUrlAnalysisEnabled] = useState(DEFAULT_URL_ANALYSIS_ENABLED);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  const fetchAppConfigDetails = async () => {
    try {
      const instance = await fetchInstanceDetails();
      const saasMode = isDefined(instance?.data?.SaaS_mode) ? instance.data.SaaS_mode : DEFAULT_SAAS_MODE_ENABLED;
      setSaasModeEnabled(saasMode);
      const urlAnalysisMode = await fetchUrlAnalysisEnabled();
      const analysisMode = isDefined(urlAnalysisMode) ? urlAnalysisMode : DEFAULT_URL_ANALYSIS_ENABLED;
      setUrlAnalysisEnabled(analysisMode);
      getSelfUser();
    } catch (err) {
      console.error('Error fetching app config details:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!auth?.isLoading) {
      fetchAppConfigDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const getSelfUser = async () => {
    if (user) {
      // Clear previous user before re-fetching.
      setUser();
    }
    return fetchSelfUser()
      .then(response => {
        if (response.data.id !== ANONYMOUS_USER_ID) {
          setUser(response.data);
        }
      });
  };

  const appConfigValues = {
    isSaasModeEnabled: saasModeEnabled,
    isUrlAnalysisEnabled: urlAnalysisEnabled,
    isLoading: loading,
    refreshUser: getSelfUser,
    user
  };

  return (
    <AppConfigContext.Provider value={appConfigValues}>
      {children}
    </AppConfigContext.Provider>
  );
};

AppConfigProvider.propTypes = {
  children: PropTypes.any
};

export { AppConfigProvider, AppConfigContext };
