import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useSnackSetState } from 'contexts/SnackContext';
import DragDropDefaultTypography from './DragDropDefaultTypography';
import { useAuth } from 'react-oidc-context';

// Warning: this error code belongs to react-dropzone library
// too-many-files
// file-too-large
const errorMessage = {
  'too-many-files': 'You can scan only one file at a time in an anonymous mode',
  'file-too-large': 'Some files exceed maximum allowed size'
};

const FileDropZone = ({
  onDropCallback,
  children,
  className,
  amountOfFiles,
  ...props
}) => {
  const setSnack = useSnackSetState();
  const auth = useAuth();
  const isGuestUser = !auth?.user?.profile?.roles || auth?.user?.profile?.roles.length === 0;

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      const [el] = fileRejections;
      setSnack({
        isOpen: true,
        message: errorMessage[el.errors[0].code],
        title: 'File!',
        severity: 'warning'
      });
    }
    if (isGuestUser && amountOfFiles === 1 && acceptedFiles.length > 0) {
      setSnack({
        isOpen: true,
        message: errorMessage['too-many-files'],
        title: 'File!',
        severity: 'warning'
      });
    } else {
      onDropCallback(acceptedFiles);
    }
  }, [onDropCallback, setSnack, amountOfFiles, isGuestUser]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...props
  });

  const renderChildren = () => children || <DragDropDefaultTypography isDragActive={isDragActive} />;

  return (
    <div {...getRootProps({ className })}>
      <input {...getInputProps()} />
      { renderChildren() }
    </div>
  );
};

FileDropZone.propTypes = {
  onDropCallback: PropTypes.func.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  amountOfFiles: PropTypes.number
};

export default FileDropZone;
