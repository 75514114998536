import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import makeStyles from './ThreatGauge.styles.js';
import { interpolateColor } from 'pages/components/ThreatScore/ThreatScore.styles.js';
import { propTypes } from './ThreatGauge.props.js';
const useStyles = makeStyles();

const ThreatGauge = ({ target }) => {
  const threatScore = target?.current_analysis?.risk_score;
  const value = threatScore;
  const classes = useStyles();

  const valueGaugeStyle = {
    transform: 'rotate(-180deg)',
    width: 135,
    color: interpolateColor(value)
  };
  const backgroundGaugeStyle = {
    transform: 'rotate(-180deg)',
    width: 135,
    zIndex: -1
  };

  if (threatScore === undefined || threatScore === null) {
    return null;
  }

  return (
    <>
    <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={value / 2}
          size={100}
          thickness={4}
          style={valueGaugeStyle}
        />
        <CircularProgress
          variant="determinate"
          value={50}
          size={100}
          thickness={4}
          className={classes.backgroundGauge}
          style={backgroundGaugeStyle}
        />
        <div>
          <Typography variant="h5" className={classes.valueText}>
            <span>{value}</span>
          </Typography>
          <Typography className={classes.label}>Threat Score</Typography>
        </div>
      </div>
    </>
  );
};
ThreatGauge.propTypes = propTypes;
export default ThreatGauge;
