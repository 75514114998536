import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDashboardData } from 'hooks/useDashboardData';
import { deleteUnusedTags } from './Dashboard.utils';
import { Grid } from '@material-ui/core';
import PageHeader from 'pages/components/PageHeader';
import Loading from 'common/Loading';
import Usage from './components/Usage';
import LineChart from './components/LineChart';
import ResultsSummary from './components/ResultsSummary';
import LastMaliciousFiles from './components/LastMaliciousFiles';
import ProbeSummary from './components/ProbeSummary/ProbeSummary';

import makeStyles from './Dashboard.styles';

const useStyles = makeStyles();

export default function Dashboard () {
  const [t] = useTranslation();
  const classes = useStyles();
  const { files, urls, tags, probesList, isDashboardLoading, platformAdmin } = useDashboardData();

  useEffect(() => {
    const runCleanup = async () => {
      await deleteUnusedTags(files, urls, tags);
    };

    (!isDashboardLoading && runCleanup());
  }, [files, urls, tags, isDashboardLoading]);

  return <>
    <PageHeader title={t('dashboard')} />
    {isDashboardLoading
      ? <Grid className={classes.loading} ><Loading size={30} /></Grid>
      : <div className={classes.container} >
        {!platformAdmin &&
          <Grid container xs={12} className={classes.sideBySide} >
            <Grid item xs={6} className={classes.itemTwo}>
              <Usage files={files} urls={urls} tags={tags} />
            </Grid>
          </Grid>
        }
        <LineChart files={files} urls={urls} />
        <Grid container xs={12} className={classes.sideBySide}>
          <Grid item xs={6} className={classes.itemOne}>
            <ResultsSummary files={files} />
          </Grid>
          <Grid item xs={6} className={classes.itemTwo}>
            <LastMaliciousFiles files={files} />
          </Grid>
        </Grid>
        {platformAdmin && <ProbeSummary probesList={probesList}/>}
      </div>
    }
  </>;
}
