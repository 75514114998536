import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Loading from 'common/Loading';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import useScanContext from 'contexts/ScanContext/useScanContext';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';
import { ROUTE_PATHS } from 'utils/routes.constants';
import { fetchCatalogProbes } from 'entities/catalogs/catalogs.api';
import { isAdmin } from 'entities/auth/auth.utils';
import { useAuth } from 'react-oidc-context';
import ProbeModel from 'common/probes/models/ProbeModel';
import TabPanel from './components/TabPanel';
import AppBar from './components/AppBar';
import ScannerChildrenHelper from './components/ScannerChildrenHelper';
import UrlScanner from './components/UrlScanner';

const DEFAULT_CATALOG = 'catalog.1';

export default function Scanner () {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { signoutSilent } = useAuth();
  const defaultTab = search.includes('tab=url') ? 1 : 0;
  const urlParams = new URLSearchParams(search).get('URL');
  const isWarningEnabled = isAdmin();
  const { isUrlAnalysisEnabled, user } = useAppConfigContext();
  const [{ isAdvancedScan }, setScanContext] = useScanContext();
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [isFileScanDisabled, setIsFileScanDisabled] = useState(false);
  const [isURLScanDisabled, setIsURLScanDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    setScanContext({ type: SCAN_ACTIONS.CLEAN_SCAN });
  }, [setScanContext]);

  useEffect(() => {
    if (isWarningEnabled) {
      fetchCatalogProbes(DEFAULT_CATALOG)
        .then(response => {
          const list = response?.data?.map?.(d => new ProbeModel(d));
          setIsFileScanDisabled(!list.some(prob => prob.isFileProbeEnabled()));
          setIsURLScanDisabled(!list.some(prob => prob.isURLProbeEnabled()));
        });
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && !user.roles.includes('user_interface_enable')) {
      localStorage.setItem('logout_error', 'You do not have the necessary permissions to log in');
      signoutSilent();
      navigate(ROUTE_PATHS.SCAN);
    }
  }, [user, navigate, signoutSilent]);

  return (
    <Grid container>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {isUrlAnalysisEnabled && !isAdvancedScan && (
            <AppBar activeTab={activeTab} handleChange={handleChange}></AppBar>
          )}
          <TabPanel value={activeTab} index={0}>
            <ScannerChildrenHelper isDisabled={isFileScanDisabled} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <UrlScanner isDisabled={isURLScanDisabled} value={urlParams} />
          </TabPanel>
        </>
      )}
    </Grid>
  );
}
