import React from 'react';
import Snack from 'common/Snack';
import { Routes, Route } from 'react-router-dom';
import ErrorPage from 'pages/ErrorPage';
import UserSettings from 'pages/UserSettings';
import DashboardLayout from 'layouts/DashboardLayout';
import routes from 'utils/getRoutes';
import SetupInterceptors from 'utils/interceptors';
import WithAuth from '../common/WithAuth';
import HomePage from '../pages/HomePage/HomePage';
import { ROUTE_PATHS } from 'utils/routes.constants';

export default function MainLayout () {
  SetupInterceptors();

  return (
    <>
        <Routes>
          <Route path='*' element={<ErrorPage />} />
          <Route path={ROUTE_PATHS.HOMEPAGE} element={<HomePage />} />
          <Route path={ROUTE_PATHS.FORBIDDEN} element={<ErrorPage />} />
          <Route path={ROUTE_PATHS.USER_SETTINGS} element={<WithAuth><UserSettings /></WithAuth>} />
          <Route element={<DashboardLayout />}>
            {
              routes.map(({ path, component: Component }) => {
                return (<Route key={path} path={path} element={<Component />} />);
              })
            }
          </Route>
        </Routes>
      <Snack />
    </>
  );
}
