import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/routes.constants';

function SetupInterceptors () {
  const { signoutSilent } = useAuth();
  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        signoutSilent();

        // Redirect to the "home" (scan) page
        navigate(ROUTE_PATHS.SCAN);
      } else if (error.response && error.response.status === 403) {
        navigate(ROUTE_PATHS.FORBIDDEN);
      }
      return Promise.reject(error);
    }
  );
}

export default SetupInterceptors;
