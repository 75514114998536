import {
  Details, OutlineCode, ArchiverIcon,
  SymbolBoolean, CodepenFill,
  Database, HistoryIcon,
  ArtifactsIcon, SymbolMethod,
  DocumentVerified, URLGlobe,
  MailUnreadLine, EnvolopeLetter, Pdf,
  OfficeDoc, File
} from 'common/Icons';
import {
  filterDataByType,
  getColumnsFromSchema
} from './Details.utils';
import {
  DomainSchema,
  DomainInfoSchema,
  EmailInfoSchema,
  EmailMetadataSchema,
  UrlSchema,
  UrlInfoSchema,
  IpSchema,
  IpInfoSchema,
  DocumentInfoSchema,
  DependencySchema,
  ExecutableInfoSchema,
  ExecutableAdvancedSchema,
  DocumentMetadataSchema,
  MetadataSchema,
  StringOfInterestSchema,
  CertInfoSchema,
  ApkInfoSchema,
  ApkMetadataSchema,
  ExecutableMetadataSchema,
  FileInfoSchema,
  FileMetadataSchema,
  OfficeInfoSchema,
  OfficeMetadataSchema,
  PdfInfoSchema,
  PdfMetadataSchema,
  PeInfoSchema,
  PeMetadataSchema
} from './schemas';
import Artifacts from './components/Artifacts';
import DetailsPanel from './components/DetailsPanel';
import MetadataTab from './components/Metadata';
import ExecutableInfo from './components/ExecutableInfo';
import FormatIdentificationTable from './components/FormatIdentificationTable';
import History from './components/History';
import Behavior from './components/Behavior';
import StringOfInterest from './components/StringOfInterest';
import CertInfo from './components/CertInfo';
import Dependency from './components/Dependency';
import ApkInfo from './components/ApkInfo';
import Process from './components/Process';
import Communication from './components/Communication';
import GenericComponent from './components/GenericComponent';

export default {
  SUMMARY: {
    order: 1,
    label: 'summary',
    component: DetailsPanel,
    iconComponent: Details,
    getProps: (fileLatestResults, fileData, workflow) => {
      return {
        data: fileData,
        threats: fileLatestResults,
        extracted: fileLatestResults,
        workflow: workflow
      };
    },
    isVisible: ({ threats }) => threats.length
  },
  METADATA: {
    order: 2,
    label: 'metadata',
    component: MetadataTab,
    iconComponent: SymbolMethod,
    getProps: (fileLatestResults, fileData) => ({
      metadata: {
        title: 'metadata',
        data: filterDataByType(fileLatestResults, 'Metadata'),
        columns: getColumnsFromSchema(MetadataSchema, 'MetadataSchema')
      },
      documentMetadata: {
        title: 'document_metadata',
        data: filterDataByType(fileLatestResults, 'DocumentMetadata'),
        columns: getColumnsFromSchema(DocumentMetadataSchema, 'DocumentMetadataSchema')
      }
    }),
    isVisible: ({ metadata, documentMetadata }) => metadata?.data?.length || documentMetadata?.data?.length
  },
  EXECUTABLE_INFO: {
    order: 3,
    label: 'executable_info',
    component: ExecutableInfo,
    iconComponent: OutlineCode,
    getProps: (fileLatestResults, fileData) => ({
      executableInfo: {
        title: 'executable_info',
        data: filterDataByType(fileLatestResults, 'ExecutableInfo'),
        columns: getColumnsFromSchema(ExecutableInfoSchema, 'ExecutableInfoSchema')
      },
      executableAdvanced: {
        title: 'executable_advanced',
        data: filterDataByType(fileLatestResults, 'ExecutableAdvanced'),
        columns: getColumnsFromSchema(ExecutableAdvancedSchema, 'ExecutableAdvancedSchema')
      }
    }),
    isVisible: ({ executableInfo, executableAdvanced }) => executableInfo?.data?.length || executableAdvanced?.data?.length
  },
  EXECUTABLE_METADATA: {
    order: 4,
    label: 'executable_metadata',
    component: GenericComponent,
    iconComponent: SymbolMethod,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'executable_metadata',
        data: filterDataByType(fileLatestResults, 'ExecutableMetadata'),
        columns: getColumnsFromSchema(ExecutableMetadataSchema, 'ExecutableMetadataSchema')
      };
    },
    isVisible: ({ data }) => data.length

  },
  FORMAT_IDENTIFICATION: {
    order: 5,
    label: 'format_identification',
    component: FormatIdentificationTable,
    iconComponent: Database,
    getProps: (fileLatestResults, fileData) => ({ data: filterDataByType(fileLatestResults, 'FormatIdentification') }),
    isVisible: ({ data }) => data.length
  },
  DOCUMENT_INFO: {
    order: 6,
    label: 'document_info',
    component: GenericComponent,
    iconComponent: ArchiverIcon,
    getProps: (fileLatestResults, fileData) => ({
      title: 'document_info',
      data: filterDataByType(fileLatestResults, 'DocumentInfo'),
      columns: getColumnsFromSchema(DocumentInfoSchema, 'DocumentInfoSchema')
    }),
    isVisible: ({ data }, type) => data.length
  },
  FILE_INFO: {
    order: 7,
    label: 'file_info',
    component: GenericComponent,
    iconComponent: File,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'file_info',
        data: filterDataByType(fileLatestResults, 'FileInfo'),
        columns: getColumnsFromSchema(FileInfoSchema, 'FileInfoSchema')
      };
    },
    isVisible: ({ data }) => data.length

  },
  FILE_METADATA: {
    order: 8,
    label: 'file_metadata',
    component: GenericComponent,
    iconComponent: SymbolMethod,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'file_metadata',
        data: filterDataByType(fileLatestResults, 'FileMetadata'),
        columns: getColumnsFromSchema(FileMetadataSchema, 'FileMetadataSchema')
      };
    },
    isVisible: ({ data }) => data.length

  },
  BEHAVIOR: {
    order: 9,
    label: 'behavior',
    component: Behavior,
    iconComponent: SymbolBoolean,
    getProps: (fileLatestResults, fileData) => ({
      data: {
        behavior: filterDataByType(fileLatestResults, 'Behavior'),
        mitreAttackTechnique: filterDataByType(fileLatestResults, 'MitreAttackTechnique')
      }
    }),
    isVisible: ({ data }) => data.behavior?.length || data.mitreAttackTechnique?.length
  },
  HISTORY: {
    order: 999,
    label: 'history',
    component: History,
    iconComponent: HistoryIcon,
    getProps: (fileLatestResults, fileData) => ({ rows: fileData?.analysis }),
    isVisible: ({ rows }) => !!rows?.length
  },
  STRING_OF_INTEREST: {
    order: 12,
    label: 'string_of_interest',
    component: StringOfInterest,
    iconComponent: CodepenFill,
    getProps: (fileLatestResults, fileData) => ({
      title: 'string_of_interest',
      data: filterDataByType(fileLatestResults, 'StringOfInterest'),
      columns: getColumnsFromSchema(StringOfInterestSchema, 'StringOfInterestSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  ARTIFACTS: {
    order: 997,
    label: 'artifacts',
    component: Artifacts,
    iconComponent: ArtifactsIcon,
    getProps: (fileLatestResults, fileData) => ({ data: { fileLatestResults, ExternalLink: filterDataByType(fileLatestResults, 'ExternalLink') } }),
    isVisible: ({ data }) => (data?.fileLatestResults?.some?.(entry => entry.artifacts.length) || data.ExternalLink?.length)
  },
  // Freysa Tabs
  DEPENDENCY: {
    order: 13,
    label: 'dependency',
    component: Dependency,
    iconComponent: CodepenFill,
    getProps: (fileLatestResults, fileData) => ({
      data: filterDataByType(fileLatestResults, 'Dependency'),
      columns: getColumnsFromSchema(DependencySchema, 'DependencySchema')
    }),
    isVisible: ({ data }) => data.length
  },
  APK_INFO: {
    order: 14,
    label: 'apk_info',
    component: ApkInfo,
    iconComponent: CodepenFill,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'apk_info',
        data: filterDataByType(fileLatestResults, 'ApkInfo'),
        columns: getColumnsFromSchema(ApkInfoSchema, 'ApkInfoSchema')
      };
    },
    isVisible: ({ data }) => data.length
  },
  APK_METADATA: {
    order: 15,
    label: 'apk_metadata',
    component: GenericComponent,
    iconComponent: CodepenFill,
    getProps: (fileLatestResults, fileData) => ({
      title: 'apk_metadata',
      data: filterDataByType(fileLatestResults, 'ApkMetadata'),
      columns: getColumnsFromSchema(ApkMetadataSchema, 'ApkMetadataSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  // URL SPECIFIC TABS
  URL: {
    order: 21,
    label: 'url',
    component: GenericComponent,
    iconComponent: URLGlobe,
    getProps: (fileLatestResults, fileData) => ({
      title: 'url',
      data: filterDataByType(fileLatestResults, 'Url'),
      columns: getColumnsFromSchema(UrlSchema, 'UrlSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  URL_INFO: {
    order: 22,
    label: 'url_info',
    component: GenericComponent,
    iconComponent: URLGlobe,
    getProps: (fileLatestResults, fileData) => (
      {
        title: 'url_info',
        data: filterDataByType(fileLatestResults, 'UrlInfo'),
        columns: getColumnsFromSchema(UrlInfoSchema, 'UrlInfoSchema')
      }),
    isVisible: ({ data }) => data.length
  },
  IP: {
    order: 23,
    label: 'ip',
    component: GenericComponent,
    iconComponent: URLGlobe,
    getProps: (fileLatestResults, fileData) => ({
      title: 'ip',
      data: filterDataByType(fileLatestResults, 'Ip'),
      columns: getColumnsFromSchema(IpSchema, 'IpSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  IP_INFO: {
    order: 24,
    label: 'ip_info',
    component: GenericComponent,
    iconComponent: OutlineCode,
    getProps: (fileLatestResults, fileData) => ({
      title: 'ip_info',
      data: filterDataByType(fileLatestResults, 'IpInfo'),
      columns: getColumnsFromSchema(IpInfoSchema, 'IpInfoSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  CERT_INFO: {
    order: 25,
    label: 'cert_info',
    component: CertInfo,
    iconComponent: DocumentVerified,
    getProps: (fileLatestResults, fileData) => ({
      title: 'cert_info',
      data: filterDataByType(fileLatestResults, 'CertInfo'),
      columns: getColumnsFromSchema(CertInfoSchema, 'CertInfoSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  DOMAIN: {
    order: 27,
    label: 'domain',
    component: GenericComponent,
    iconComponent: SymbolMethod,
    getProps: (fileLatestResults, fileData) => ({
      title: 'Domain',
      data: filterDataByType(fileLatestResults, 'Domain'),
      columns: getColumnsFromSchema(DomainSchema, 'DomainSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  DOMAIN_INFO: {
    order: 26,
    label: 'domain_info',
    component: GenericComponent,
    iconComponent: SymbolMethod,
    getProps: (fileLatestResults, fileData) => ({
      title: 'domain_info',
      data: filterDataByType(fileLatestResults, 'DomainInfo'),
      columns: getColumnsFromSchema(DomainInfoSchema, 'DomainInfoSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  COMMUNICATION: {
    order: 28,
    label: 'communication',
    component: Communication,
    iconComponent: Details,
    getProps: (fileLatestResults, fileData) => ({ data: filterDataByType(fileLatestResults, 'Communication') }),
    isVisible: ({ data }) => data.length
  },
  PROCESS: {
    order: 29,
    label: 'process',
    component: Process,
    iconComponent: SymbolBoolean,
    getProps: (fileLatestResults, fileData) => ({ data: filterDataByType(fileLatestResults, 'Process') }),
    isVisible: ({ data }) => data.length
  },
  EMAIL_INFO: {
    order: 30,
    label: 'email_info',
    component: GenericComponent,
    iconComponent: MailUnreadLine,
    getProps: (fileLatestResults, fileData) => ({
      title: 'email_info',
      data: filterDataByType(fileLatestResults, 'EmailInfo'),
      columns: getColumnsFromSchema(EmailInfoSchema, 'EmailInfoSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  EMAIL_METADATA: {
    order: 31,
    label: 'email_metadata',
    component: GenericComponent,
    iconComponent: EnvolopeLetter,
    getProps: (fileLatestResults, fileData) => ({
      title: 'email_metadata',
      data: filterDataByType(fileLatestResults, 'EmailMetadata'),
      columns: getColumnsFromSchema(EmailMetadataSchema, 'EmailMetadataSchema')
    }),
    isVisible: ({ data }) => data.length
  },
  OFFICE_INFO: {
    order: 32,
    label: 'office_info',
    component: GenericComponent,
    iconComponent: OfficeDoc,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'office_info',
        data: filterDataByType(fileLatestResults, 'OfficeInfo'),
        columns: getColumnsFromSchema(OfficeInfoSchema, 'OfficeInfoSchema')
      };
    },
    isVisible: ({ data }) => data.length
  },
  OFFICE_METADATA: {
    order: 33,
    label: 'office_metadata',
    component: GenericComponent,
    iconComponent: SymbolMethod,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'office_metadata',
        data: filterDataByType(fileLatestResults, 'OfficeMetadata'),
        columns: getColumnsFromSchema(OfficeMetadataSchema, 'OfficeMetadataSchema')
      };
    },
    isVisible: ({ data }) => data.length
  },
  PDF_INFO: {
    order: 34,
    label: 'pdf_info',
    component: GenericComponent,
    iconComponent: Pdf,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'pdf_info',
        data: filterDataByType(fileLatestResults, 'PdfInfo'),
        columns: getColumnsFromSchema(PdfInfoSchema, 'PdfInfoSchema')
      };
    },
    isVisible: ({ data }) => data.length
  },
  PDF_METADATA: {
    order: 35,
    label: 'pdf_metadata',
    component: GenericComponent,
    iconComponent: SymbolMethod,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'pdf_metadata',
        data: filterDataByType(fileLatestResults, 'PdfMetadata'),
        columns: getColumnsFromSchema(PdfMetadataSchema, 'PdfMetadataSchema')
      };
    },
    isVisible: ({ data }) => data.length
  },
  PE_INFO: {
    order: 36,
    label: 'pe_info',
    component: GenericComponent,
    iconComponent: OutlineCode,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'pe_info',
        data: filterDataByType(fileLatestResults, 'PeInfo'),
        columns: getColumnsFromSchema(PeInfoSchema, 'PeInfoSchema')
      };
    },
    isVisible: ({ data }) => data.length
  },
  PE_METADATA: {
    order: 37,
    label: 'pe_metadata',
    component: GenericComponent,
    iconComponent: SymbolMethod,
    getProps: (fileLatestResults, fileData) => {
      return {
        title: 'pe_metadata',
        data: filterDataByType(fileLatestResults, 'PeMetadata'),
        columns: getColumnsFromSchema(PeMetadataSchema, 'PeMetadataSchema')
      };
    },
    isVisible: ({ data }) => data.length
  }
};
