import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { ArrowDown, ArrowRight, InfoIcon } from 'common/Icons';
import { TreeView } from 'common/TreeView';
import FolderItem from './components/FolderItem';
import ChildItem from './components/ChildItem';
import { propTypes } from './ArchivesSidebar.props';
import makeStyles from './ArchivesSidebar.styles';

export default function ArchivesSidebar ({ archives, setActiveTab }) {
  const [showWarning, setShowWarning] = useState(false);
  const [totalFiles, setSetTotalFiles] = useState(false);
  const { state } = useLocation();
  const prevSearchQuery = state?.prevSearchQuery;
  const hasUrlChild = archives.children.some(child => child.id.includes('url'));

  const [t] = useTranslation();

  const useStyles = makeStyles();
  const classes = useStyles();

  useEffect(() => {
    const totalFiles = archives.children.length + 1;
    setSetTotalFiles(totalFiles);
    if (totalFiles > 100) {
      setShowWarning(true);
    };
  }, [archives]);

  const handleArchiveClick = () => { setActiveTab(0); };

  const archivesData = [
    {
      id: 1,
      content: <FolderItem archives={archives} handleArchiveClick={handleArchiveClick} prevSearchQuery={prevSearchQuery}/>,
      child: archives.children.slice(0, 100).map(child => ({
        id: child.id,
        content: <ChildItem key={child.id} parent={archives?.parent} child={child} handleArchiveClick={handleArchiveClick} prevSearchQuery={prevSearchQuery} />
      }))
    }
  ];

  return (
    <Grid item className={classes.archiveMenu}>
      {showWarning && <Typography variant="body2" className={classes.warningText}><InfoIcon color = 'black' className={classes.infoIcon}/> {t('only_first_100_warning')}</Typography>}
      {hasUrlChild && <Typography variant="body2" className={classes.warningText}><InfoIcon color = 'black' className={classes.infoIcon}/> {t('file_contain_urls')}</Typography>}
      <div className={classes.sidebarHeader}>
        <Typography variant="body1" gutterBottom>
          {t('files')}
        </Typography>
        <span>{totalFiles}</span>
      </div>
      <TreeView
        data={archivesData}
        className={classes.treeView}
        defaultExpanded={[1]}
        defaultCollapseIcon={<ArrowDown size={20} />}
        defaultExpandIcon={<ArrowRight size={20} />}
      />
    </Grid>
  );
}

ArchivesSidebar.propTypes = propTypes;
