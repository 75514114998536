import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { URLGlobe } from 'common/Icons';
import { fetchAnalysisRequest, startURLAnalysisRequest } from 'entities/analysisRequest/analysisRequest.api';
import { uploadURL } from 'entities/urls/urls.api';
import { List } from 'components/List';
import Row from './Row';
import { ANALYSIS_MS_INTERVAL, STATUSES } from './ScanResults.constants';
import useScanContext from 'contexts/ScanContext/useScanContext';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';
import { defaultAnalysis } from './ScanResults.utils';
import { propTypes } from './ScanResults.props';
import makeStyles from './styles';

const useStyles = makeStyles();

const ScanResults = ({ urls = [], onNewScan, apkScan }) => {
  const [t] = useTranslation();
  const [scanContext, setScanContext] = useScanContext();

  const pullingRef = useRef({ stopPull: false, interval: null });
  const [statusError, setStatusError] = useState();
  const [analysisResponse, setAnalysisResponse] = useState(defaultAnalysis(urls));

  const classes = useStyles();
  const navigate = useNavigate();

  const handleNewScan = () => {
    cancelPulling();
    onNewScan();
  };

  const cancelPulling = () => {
    if (pullingRef.current?.interval) {
      clearInterval(pullingRef.current.interval);
    } else {
      // Remember to cancel later.
      pullingRef.current.stopPull = true;
    };
    setScanContext({ type: SCAN_ACTIONS.CLEAN_SCAN });
  };

  const redirectToAnalysisPage = useCallback(analysisId => {
    const searchQuery = `id=${analysisId}`;
    navigate(`/ui/results/url?${searchQuery}`);
  }, [navigate]);

  const createURLs = async () => {
    let analyzedURLsIds;
    try {
      const response = await Promise.all([...urls.map(uploadURL)]);
      analyzedURLsIds = response.map(res => res.data.id);
      return analyzedURLsIds;
    } catch {
      setStatusError(STATUSES.UPLOAD_FAILED);
      throw (STATUSES.UPLOAD_FAILED);
    }
  };

  const startAnalysis = async ids => {
    try {
      const response = await startURLAnalysisRequest(ids, {
        workflow_options: (scanContext.probes.length > 0 ? { probelist: scanContext.probes } : {}),
        force: false,
        priority: 'MEDIUM',
        workflow: apkScan ? 'apk-scan' : 'default'
      });
      setAnalysisResponse(response.data ?? {});
      return response.data.id;
    } catch {
      setStatusError(STATUSES.SCAN_FAILED);
      throw (STATUSES.SCAN_FAILED);
    }
  };

  const createURLsScanAndPull = async () => {
    const ids = await createURLs();
    setAnalysisResponse(defaultAnalysis(urls, STATUSES.RUNNING));
    const analysisRequestId = await startAnalysis(ids);
    startPullingResults(analysisRequestId);
  };

  const startPullingResults = id => {
    // Keep pulling until:
    // Scan ends (return the results),
    // Or an error occurs (update scan status).
    return new Promise((resolve, reject) => {
      pullingRef.current.interval = setInterval(async () => {
        // Check if an order to cancel pulling exist.
        if (pullingRef.current.stopPull) {
          cancelPulling();
          return;
        }
        try {
          const analysisRequest = await fetchAnalysisRequest(id);
          setAnalysisResponse(analysisRequest.data);
          const finishedAnalysis = analysisRequest.data?.analysis
            ?.filter(analysis => analysis.status !== 'RUNNING');
          if (finishedAnalysis.length === urls.length) {
            cancelPulling();
            setScanContext({ type: SCAN_ACTIONS.CLEAN_SCAN });
            redirectToAnalysisPage(analysisRequest.data.id);
          }
        } catch (e) {
          cancelPulling();
          setStatusError(STATUSES.PULLING_FAILED);
          reject(e);
        }
      }, ANALYSIS_MS_INTERVAL);
    });
  };

  useEffect(() => {
    createURLsScanAndPull();

    return cancelPulling;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item container xs={8} justify="center">
        <URLGlobe className={classes.bigIcon} />
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
        >
          {urls.length > 1 ? t('the_urls_are_being_scanned_by_our_probes') : t('the_url_is_being_scanned_by_our_probes') }
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <List aria-label="file preview list">
          {
            analysisResponse.analysis.map((analysis, index) =>
              <Row key={analysis.target.url + index} analysis={analysis} statusError={statusError}
              />
            )}
        </List>
      </Grid>
      <Grid item container justify="center" lg={12}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            fullWidth
            style={{ margin: 12 }}
            onClick={handleNewScan}
            color="primary"
          >
           {t('new_scan')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

ScanResults.propTypes = propTypes;

export default ScanResults;
