import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Typography, Tooltip } from '@material-ui/core';
import { useAuth } from 'react-oidc-context';
import { propTypes } from './FileName.props';
import StatusIcon from 'pages/components/StatusIcon';
import { ROUTE_PATHS } from 'utils/routes.constants';

const Filename = ({ rowData }) => {
  const auth = useAuth();
  return auth?.user?.profile?.roles && auth?.user?.profile?.roles.length > 0
    ? (
        <>
          <StatusIcon currentAnalysis={rowData?.current_analysis} />
          <Tooltip
            title={rowData.id}
            className="tooltip"
            arrow
          >
            <Link to={generatePath(ROUTE_PATHS.DETAILS_FILE, { id: rowData.id })}>{rowData.name ?? rowData.url}</Link>
          </Tooltip>
        </>
      )
    : (
        <Typography>
          { rowData.name ?? rowData.url }
        </Typography>
      );
};

Filename.propTypes = propTypes;

export default Filename;
