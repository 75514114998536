import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import { AuthProvider } from 'react-oidc-context';
import { keycloakConfig } from 'keycloakConfig';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AuthProvider {...keycloakConfig}>
    <App />
  </AuthProvider>
);
