import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, TextField } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import BaseLayout from 'layouts/BaseLayout';
import { getUser } from 'entities/auth/auth.utils';
import PageHeader from 'pages/components/PageHeader';
import makeStyles from './UserSettings.styles';

const useStyles = makeStyles();

export default function UserSettings () {
  const [t] = useTranslation();
  const classes = useStyles();
  const user = getUser();
  const navigate = useNavigate();

  return (
    <BaseLayout>
        <Grid container justify="center" alignItems="center">
        <Grid item md={8}>
          <div className={classes.backIcon} onClick={() => navigate(-1)}> <ArrowBack /> {t('back')}</div>
          <PageHeader title={t('user_details')} />
          <Typography className={classes.userSettingsDescription}>{t('below_details_profile')} </Typography>
          <form className={classes.formContainer}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="preferred_username"
              label="Preferred Username"
              name="preferred_username"
              value={user?.profile?.preferred_username}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="given_name"
              label="Given Name"
              name="given_name"
              value={user?.profile?.given_name}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="family_name"
              label="Family Name"
              name="family_name"
              value={user?.profile?.family_name}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={user?.profile?.email}
              disabled
            />
          </form>
        </Grid>
      </Grid>
    </BaseLayout>
  );
}
