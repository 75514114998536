import { makeStyles } from '@material-ui/core/styles';

export const interpolateColor = (value) => {
  let color;

  if (value >= 75) {
    color = 'rgb(250, 75, 84)';
  } else if (value >= 60) {
    color = 'rgb(255, 161, 67)';
  } else if (value >= 10) {
    color = 'rgb(48, 176, 67)';
  } else {
    color = 'rgb(22, 116, 36)';
  }

  return color;
};

const useStyles = makeStyles((theme) => ({
  rootProgress: {
    height: 10,
    borderRadius: 5,
    marginTop: 5
  },
  bar: {
    height: 10,
    borderRadius: 5
  },
  barColor: (props) => ({
    backgroundColor: interpolateColor(props.value)
  }),
  grey: {
    backgroundColor: theme.palette.grey[300]
  }
}));

export default useStyles;
